<template>
  <v-card class="pa-8" width="100%">
    <h1 style="font-weight: 800; color: #e53935">ข้อมูลผู้ใช้งาน</h1>
    <v-row>
      <v-col cols="2"><p style="font-weight:800">name</p></v-col>
      <v-col cols="4">
        <span>{{ data.prename }} {{ data.name}} {{ data.surname }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p style="font-weight:800">nickname</p></v-col>
      <v-col cols="4">
        <span>{{ data.nickname }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2"><p style="font-weight:800">empCode</p></v-col>
      <v-col cols="4">
        <span>{{ data.empCode }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">idNo</p></v-col>
      <v-col cols="4">
        <span>{{ data.idNo }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">school</p></v-col>
      <v-col cols="4">
        <span>{{ data.school }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">level</p></v-col>
      <v-col cols="4">
        <span>{{ data.level }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">section</p></v-col>
      <v-col cols="4">
        <span>{{ data.section }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">room</p></v-col>
      <v-col cols="4">
        <span>{{ data.room }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">mobile</p></v-col>
      <v-col cols="4">
        <span>{{ data.mobile }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">email</p></v-col>
      <v-col cols="4">
        <span>{{ data.email }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">accountNo</p></v-col>
      <v-col cols="4">
        <span>{{ data.accountNo }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">status</p></v-col>
      <v-col cols="4">
        <span>{{ data.status }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">startDate</p></v-col>
      <v-col cols="4">
        <span>{{ data.startDate }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">endDate</p></v-col>
      <v-col cols="4">
        <span>{{ data.endDate }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">advisorName</p></v-col>
      <v-col cols="4">
        <span>{{ data.advisorName }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">advisorMobile</p></v-col>
      <v-col cols="4">
        <span>{{ data.advisorMobile }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800">profileImg</p></v-col>
      <v-col cols="4">
        <v-img :src="data.profileImg" width="100px"></v-img>
      </v-col>
       <v-col cols="2"><p style="font-weight:800">behavior1</p></v-col>
      <v-col cols="4">
        <span>{{ data.behavior1 }}</span>
      </v-col>
       <v-col cols="2"><p style="font-weight:800">behavior2</p></v-col>
      <v-col cols="4">
        <span>{{ data.behavior2 }}</span>
      </v-col>
       <v-col cols="2"><p style="font-weight:800">behavior3</p></v-col>
      <v-col cols="4">
        <span>{{ data.behavior3 }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight:800" class="pt-5">activeFlag</p></v-col>
      <v-col cols="4">
        <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
      </v-col>
     
    </v-row>
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn @click="submit()">บันทึก</v-btn> -->
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
     prenameItems: ["นาย", "นาง", "นางสาว"],
      levelItems: ["ปวช", "ปวส", "ป ตรี"],
      sectionItems: ["ไฟฟ้ากำลัง", "ช่างยนต์", "ช่างกล", "เครื่องกลอุตสาหกรรม"],
      schoolItems: [],
      StatusItems: ["Activate", "Left", "Quarantine"],
      menufrom: false,
      menuto: false,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      data: {
        prename: "",
        name: "",
        surname: "",
        nickname: "",
        empCode:"",
        idNo: "",
        school: "",
        level: "",
        section: "",
        room: "",
        mobile: "",
        email: "",
        accountNo: "",
        status: "",
        startDate: "",
        endDate: "",
        advisorName: "",
        advisorMobile: "",
        profileImg: "",
        behavior1: "",
        behavior2: "",
        behavior3: "",
      },
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.data = userdata
  },
  methods: {
    cancel() {
      this.$router.push("ManageUser");
    },
  },
};
</script>